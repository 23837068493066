import { FC } from 'react'
import Navbar from '../navbar'
import Footer from '../footer'
import { LayoutProperties } from './interfaces'

const Layout: FC<LayoutProperties> = ({ children }) => {
  return (
    <>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
export { Layout }
