import { useState, useEffect, FC } from 'react'
import {
  Container,
  // Collapse,
  Navbar,
  // NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  // NavbarText,
} from 'reactstrap'
import classnames from 'classnames'
// import AnchorLink from 'react-anchor-link-smooth-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagram,
  faSoundcloud,
  faSpotify,
} from '@fortawesome/free-brands-svg-icons'
import Logo from '../../assets/images/logo.png'
import { Link } from 'gatsby'
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

const NavbarCustom: FC = () => {
  // const [isOpen, setIsOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 80) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  // const toggle = () => setIsOpen(!isOpen)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <header className={classnames({ scrolled })}>
      <Container fluid>
        <Navbar dark className="main-navbar" sticky="top" expand="lg">
          <NavbarBrand
            href="/"
            title="Home"
            className="mr-auto main-navbar--brand"
          >
            <figure className="main-navbar--logo m-0 p-0 animate__animated animate__backInLeft">
              <img src={Logo} className="img-fluid" alt="logo" />
            </figure>
          </NavbarBrand>
          {/* <NavbarToggler
            className="main-navbar--toggler p-0 w-0"
            onClick={toggle}
          /> */}
          {/* <Collapse isOpen={isOpen} navbar className="justify-content-end"> */}
          <Nav
            className="main-navbar--nav flex-row mt-md-0 animate__animated animate__fadeInRight"
            navbar
          >
            {/*<NavItem>
              <NavLink

                className="icon"
                target="_blank"
                href="/PRESS_2024_NAVIA_ESPAÑOL.pdf"
              >
                <FontAwesomeIcon
                  className="fa-icon"
                  icon={faGlobeAmericas}
                  width={70}
                  height={90}
                />
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                className="icon"
                target="_blank"
                href="/PRESS_2024_NAVIA.pdf"
              >
                <FontAwesomeIcon
                  className="fa-icon"
                  icon={faGlobeAmericas}
                  width={70}
                  height={90}
                />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="icon"
                href="https://open.spotify.com/artist/7MKZ2IhERa3ZnWz9pHWHoj?si=aHZWxp4aTbmy7_jZAMYFxQ"
              >
                <FontAwesomeIcon
                  className="fa-icon"
                  icon={faSpotify}
                  width={70}
                  height={90}
                />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="icon"
                href="https://www.instagram.com/navia_dj/"
              >
                <FontAwesomeIcon
                  className="fa-icon"
                  icon={faInstagram}
                  width={70}
                  height={90}
                />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="icon"
                href="https://soundcloud.com/naviamusic"
              >
                <FontAwesomeIcon className="fa-icon" icon={faSoundcloud} />
              </NavLink>
            </NavItem>
          </Nav>
          {/* <NavbarText className="px-0 px-md-3 d-block d-lg-inline text-right">
              <a href="tel:" className="button m-auto text-right">
                Phone number
              </a>
            </NavbarText> */}
          {/* </Collapse> */}
        </Navbar>
      </Container>
    </header>
  )
}

export default NavbarCustom
